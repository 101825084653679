import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import PageHero from "../components/PageHero/PageHero";
import Rule from "../components/Rules/Rule";
import Rules from "../components/Rules/Rules";

const padelregler = ({ data: { page } }) => {
  const { title, image } = page.enKolumn;
  const { rule } = page.regler;
  return (
    <Layout>
      <Seo
        title={title}
        image={image?.localFile?.childImageSharp.original?.src}
      />
      <PageHero title={title} src={image?.localFile} />

      <section className="border-t border-opacity-10 border-lightest">
        <Rules>
          {rule.map((item, index) => (
            <Rule key={index} title={item.title} text={item.text} />
          ))}
        </Rules>
      </section>
    </Layout>
  );
};

export default padelregler;

export const pageQuery = graphql`
  query RulesPage {
    page: wpPage(title: { eq: "Padelregler" }) {
      id
      regler {
        rule {
          text
          title
        }
      }
      enKolumn {
        title
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
