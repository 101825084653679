import React from 'react'
import parse from 'html-react-parser'
import './Rule.scss'

const Rule = ({ text, title }) => {
  return (
    <section
      className="rule"
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-easing="ease"
    >
      <div className="container w-full flex justify-center">
        <div className="inner">
          <h2 className="h4 text-center">{title}</h2>
          <div className="content text-left text-white opacity-70">
            {parse(text)}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Rule
